<template>
    <div id="app">
      <nav class="navbar bg-dark">
        <div class="container-fluid">
          <a class="navbar-brand text-light" href="#">Verifica copertura</a>
        </div>
      </nav>
      <div class="container" v-if="login">
        <div>
          Comune
          <vue-select label="comuni" :filterable="false" :options="comuni" @search="onSearchComuni"
            id="comune-select" :value="comune" @input="setComune"
            :getOptionLabel="comune => comune.nome">
            <template slot="no-options">
              Digita per cercare un comune
            </template>
            <template slot="option" slot-scope="comune">
              <div class="d-center">
                <b>{{ comune.nome }}</b> ({{ comune.provincia }})
              </div>
            </template>
            <template slot="selected-option" slot-scope="comune">
              <div class="selected d-center">
                <b>{{ comune.nome }}</b> ({{ comune.provincia }})
              </div>
            </template>
          </vue-select>
          Indirizzo
          <vue-select label="via" :filterable="false" :options="vie" @search="onSearchVie"
            id="vie-select" :value="via" @input="setVia"
            :getOptionLabel="via => via.nome">
            <template slot="no-options">
              Digita per cercare una via
            </template>
            <template slot="option" slot-scope="via">
              <div class="d-center">
                {{ via.partTop }} {{ via.nome }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="via">
              <div class="selected d-center">
                {{ via.partTop }} {{ via.nome }}
              </div>
            </template>
          </vue-select>
          Civico
          <vue-select label="civico" :filterable="true" :options="civici"
            id="civici-select" class="mb-2 mr-sm-2 mb-sm-0" :value="civico" @input="setCivico"
            :getOptionLabel="civico => civico.civico">
            <template slot="no-options">
              Digita per cercare un civico
            </template>
            <template slot="option" slot-scope="civico">
              <div class="d-center">
                {{ civico.civico }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="civico">
              <div class="selected d-center">
                {{ civico.civico }}
              </div>
            </template>
          </vue-select>
          <div>Tipo di ricerca servizi</div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="flagNetmap" value="netmap" v-model="flags">
            <label class="form-check-label" for="flagNetmap">Netmap</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="flagNetmapDistributori" :disabled="!flags.includes('netmap')" value="netmap-distributori" v-model="flags">
            <label class="form-check-label" for="flagNetmapDistributori">Coordinate distributori di Netmap</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="flagCoordinateEdificiNetmap" :disabled="!flags.includes('netmap')" value="netmap-coordinate-edifici" v-model="flags">
            <label class="form-check-label" for="flagCoordinateEdificiNetmap">Coordinate edifici Netmap</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="flagNetmapRete" :disabled="!flags.includes('netmap')" value="netmap-coordinate-rete" v-model="flags">
            <label class="form-check-label" for="flagNetmapRete">Coordinate rete di Netmap</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="flagNetmapPianificazioni" :disabled="!flags.includes('netmap')" value="netmap-pianificazioni" v-model="flags">
            <label class="form-check-label" for="flagNetmapPianificazioni">Pianificazioni di Netmap</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="flagPTEFiberCop" :disabled="!flags.includes('netmap-coordinate-rete')" value="fibercop-pte" v-model="flags">
            <label class="form-check-label" for="flagPTEFiberCop">FiberCop PTE</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="flagFastweb" :disabled="!flags.includes('netmap')" value="fastweb" v-model="flags">
            <label class="form-check-label" for="flagFastweb">Fastweb</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="flagOpenFiber" value="openfiber" v-model="flags">
            <label class="form-check-label" for="flagOpenFiber">OpenFiber</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="flagEolo" value="eolo" v-model="flags">
            <label class="form-check-label" for="flagEolo">Eolo</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="flagFwa" value="fwa-lte" v-model="flags">
            <label class="form-check-label" for="flagFwa">FWA LTE-NR</label>
          </div>
        </div>
        <div v-if="loading" class="d-flex justify-content-center mt-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Ricerca servizi in corso...</span>
          </div>
        </div>
        <div class="mt-4 alert alert-danger" role="alert" v-if="error === true">
          Error
        </div>
        <div class="mt-4" v-else-if="copertura !== null">
          <button data-bs-toggle="modal" data-bs-target="#raw" class="m-2 btn btn-warning">Raw data</button>
          <button data-bs-toggle="modal" data-bs-target="#map" class="m-2 btn btn-success" v-if="coordinateEnabled">Apri mappa</button>
          <div class="card-group" v-if="civico.civico !== 'OF_DB_VIA' && via.nome !== 'OF_DB_COMUNE'">
            <div v-for="(item, index) in copertura.openFiberEntries" :key="'of-'+index" class="mt-4">
              <div class="card me-md-4" v-if="parseInt(item.statoBuilding.split('(')[1].split(')')) < 200">
                <div class="card-header">
                  FTTH OpenFiber {{ item.databaseCDBul ? 'BUL' : 'A&B' }}
                </div>
                <div class="card-body">
                  <p class="card-text">NOME SCALA: {{ item.indirizzoCensito.nomeScala }}</p>
                  <p class="card-text">ID SCALA: {{ item.indirizzoCensito.idScala }}</p>
                  <p class="card-text">CIVICO: {{ item.indirizzoCensito.civico }}</p>
                  <p class="card-text">STATO BUILDING: {{ item.statoBuilding }}</p>
                  <p class="card-text">STATO SCALA: {{ item.statoScala }}</p>
                  <p class="card-text">CIVICO IN AREA BUL: {{ item.databaseCDBul }}</p>
                  <button data-bs-toggle="modal" :data-bs-target="'#collapse-ftthof-'+index" class="m-2 btn btn-primary">Altre info
                  </button>
                  <button data-bs-toggle="modal" :data-bs-target="'#raw-ftthof-'+index" class="m-2 btn btn-warning">Raw data</button>
                  <div class="modal" :id="'collapse-ftthof-'+index">
                    <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <p class="card-text">
                            COORDINATE BUILDING:
                            <a class="link-primary" target="_blank"
                              :href="'https://www.google.com/maps/search/?api=1&query='+ item.indirizzoCensito.coordinateBuilding.replace('N','').replace('_',',').replace('E','')">
                            {{ item.indirizzoCensito.coordinateBuilding.replace('N', '').replace('_', ' ').replace('E', '') }}</a>
                          </p>
                          <p class="card-text">POP: {{ item.pop }}</p>
                          <p class="card-text">TOTALE UI: {{ item.totaleUi }}</p>
                          <p class="card-text">ULTIMA MODIFICA RECORD: {{ item.ultimaModificaRecord }}</p>
                          <p class="card-text">ULTIMA MODIFICA STATO BUILDING: {{ item.ultimaModificaStatoBuilding }}
                          </p>
                          <p class="card-text">ULTIMA MODIFICA STATO SCALA: {{ item.ultimaModificaStatoScala }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal" :id="'raw-ftthof-'+index">
                    <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <pre>{{ item }}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card me-md-4" v-else-if="parseInt(item.statoBuilding.split('(')[1].split(')')) < 300">
                <div class="card-header">
                  FWA OpenFiber {{ item.databaseCDBul ? 'BUL' : 'A&B' }}
                </div>
                <div class="card-body">
                  <p class="card-text">NOME SCALA: {{ item.indirizzoCensito.nomeScala }}</p>
                  <p class="card-text">ID SCALA: {{ item.indirizzoCensito.idScala }}</p>
                  <p class="card-text">CIVICO: {{ item.indirizzoCensito.civico }}</p>
                  <p class="card-text">STATO BUILDING: {{ item.statoBuilding }}</p>
                  <p class="card-text">STATO SCALA: {{ item.statoScala }}</p>
                  <p class="card-text">CIVICO IN AREA BUL: {{ item.databaseCDBul }}</p>
                  <button data-bs-toggle="modal" :data-bs-target="'#collapse-fwaof-'+index" class="m-2 btn btn-primary">Altre info
                  </button>
                  <button data-bs-toggle="modal" :data-bs-target="'#raw-fwaof-'+index" class="m-2 btn btn-warning">Raw data</button>
                  <div class="modal" :id="'collapse-ftthof-'+index">
                    <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <p class="card-text">
                            COORDINATE BUILDING:
                            <a class="link-primary" target="_blank"
                              :href="'https://www.google.com/maps/search/?api=1&query='+ item.indirizzoCensito.coordinateBuilding.replace('N','').replace('_',',').replace('E','')">
                            {{ item.indirizzoCensito.coordinateBuilding.replace('N', '').replace('_', ' ').replace('E', '') }}</a>
                          </p>
                          <p class="card-text">POP: {{ item.pop }}</p>
                          <p class="card-text">TOTALE UI: {{ item.totaleUi }}</p>
                          <p class="card-text">ULTIMA MODIFICA RECORD: {{ item.ultimaModificaRecord }}</p>
                          <p class="card-text">ULTIMA MODIFICA STATO BUILDING: {{ item.ultimaModificaStatoBuilding }}</p>
                          <p class="card-text">ULTIMA MODIFICA STATO SCALA: {{ item.ultimaModificaStatoScala }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal" :id="'raw-fwaof-'+index">
                    <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <pre>{{ item }}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card me-md-4" v-else>
                <div class="card-header">
                  FTTH OpenFiber P2P {{ item.databaseCDBul ? 'BUL' : 'A&B' }}
                </div>
                <div class="card-body">
                  <p class="card-text">NOME SCALA: {{ item.indirizzoCensito.nomeScala }}</p>
                  <p class="card-text">ID SCALA: {{ item.indirizzoCensito.idScala }}</p>
                  <p class="card-text">CIVICO: {{ item.indirizzoCensito.civico }}</p>
                  <p class="card-text">STATO BUILDING: {{ item.statoBuilding }}</p>
                  <p class="card-text">STATO SCALA: {{ item.statoScala }}</p>
                  <p class="card-text">CIVICO IN AREA BUL: {{ item.databaseCDBul }}</p>
                  <button data-bs-toggle="modal" :data-bs-target="'#collapse-p2pof-'+index" class="m-2 btn btn-primary">Altre info
                  </button>
                  <button data-bs-toggle="modal" :data-bs-target="'#raw-p2pof-'+index" class="m-2 btn btn-warning">Raw data</button>
                  <div class="modal" :id="'collapse-p2pof-'+index">
                    <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <p class="card-text">
                            COORDINATE BUILDING:
                            <a class="link-primary" target="_blank"
                              :href="'https://www.google.com/maps/search/?api=1&query='+ item.indirizzoCensito.coordinateBuilding.replace('N','').replace('_',',').replace('E','')">
                            {{ item.indirizzoCensito.coordinateBuilding.replace('N', '').replace('_', ' ').replace('E', '') }}</a>
                          </p>
                          <p class="card-text">POP: {{ item.pop }}</p>
                          <p class="card-text">TOTALE UI: {{ item.totaleUi }}</p>
                          <p class="card-text">ULTIMA MODIFICA RECORD: {{ item.ultimaModificaRecord }}</p>
                          <p class="card-text">ULTIMA MODIFICA STATO BUILDING: {{ item.ultimaModificaStatoBuilding }}</p>
                          <p class="card-text">ULTIMA MODIFICA STATO SCALA: {{ item.ultimaModificaStatoScala }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal" :id="'raw-p2pof-'+index">
                    <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <pre>{{ item }}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="(item, index) in copertura.netmapEntries" :key="'tim-'+index" class="mt-4">
              <div class="card me-md-4" v-if="item.ftth.stato !== 'NO'">
                <div class="card-header">
                  FTTH TIM Wholesale
                </div>
                <div class="card-body">
                  <p class="card-text">STATO: {{ item.ftth.stato }} {{ item.ftth.dataPianificazione }}</p>
                  <p class="card-text">TIPO EDR: {{ item.ftth.tipoEdr }}</p>
                  <p class="card-text">EDR: {{ item.ftth.edr }}</p>
                  <p class="card-text" v-for="(_, i) in item.ftth.oltApparatiCentrali"
                    :key="'info-ftth-'+index+'-oltApparatiCentrali-'+i">
                    OLT: {{ item.ftth.oltApparatiCentrali[i].vendor_olt }} {{ item.ftth.oltApparatiCentrali[i].clli_olt }}
                  </p>
                  <button data-bs-toggle="modal" :data-bs-target="'#collapse-ftth-'+index" class="m-2 btn btn-primary">Altre info
                  </button>
                  <button data-bs-toggle="modal" :data-bs-target="'#raw-ftth-'+index" class="m-2 btn btn-warning">Raw data</button>
                  <div class="modal" :id="'collapse-ftth-'+index">
                    <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <p class="card-text">
                            COORDINATE EDIFICIO:
                            <a class="link-primary" target="_blank"
                              :href="'https://www.google.com/maps/search/?api=1&query='+ item.indirizzoCensito.latitudineCivico+','+item.indirizzoCensito.longitudineCivico">
                            {{ item.indirizzoCensito.latitudineCivico + ' ' + item.indirizzoCensito.longitudineCivico }}</a>
                          </p>
                          <p class="card-text">CODICE EDIFICIO TIM:
                            {{ item.indirizzoCensito.codiceToponomasticoTelecom }}
                          </p>
                          <p class="card-text">TIPO DISTIBUTORE:
                            {{ item.indirizzoCensito.terminazioneTelecom.tipoTerminazione }}
                          </p>
                          <p class="card-text">CODICE DISTIBUTORE TIM:
                            {{ item.indirizzoCensito.terminazioneTelecom.codiceTerminazioneTelecom }}
                          </p>
                          <p class="card-text">
                            CIRCUITO DISTRIBUTORE:
                          <ul>
                            <li v-for="(it, i) in item.indirizzoCensito.terminazioneTelecom.circuitoDistributore"
                              :key="'dist-'+index+'-'+i"> {{ it }}
                            </li>
                          </ul>
                          </p>
                          <p class="card-text">INDIRIZZO DISTIBUTORE:
                            {{ item.indirizzoCensito.terminazioneTelecom.indirizzoDistributore.via }}
                            {{ item.indirizzoCensito.terminazioneTelecom.indirizzoDistributore.civico }}
                            {{ item.indirizzoCensito.terminazioneTelecom.indirizzoDistributore.nomeComune }}
                          </p>
                          <p class="card-text" v-for="(_, i) in item.ftth.cno_cro"
                            :key="'info-ftth-'+index+'-cno-'+i">
                            - {{ item.ftth.cno_cro[i].elemento_ottico.id_elemento_esteso }} (
                            <a class="link-primary" target="_blank"
                              :href="'https://www.google.com/maps/search/?api=1&query='+ item.ftth.cno_cro[i].elemento_ottico.latitudine+','+ item.ftth.cno_cro[i].elemento_ottico.longitudine">
                            {{ item.ftth.cno_cro[i].elemento_ottico.indirizzo }}</a>
                            )
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal" :id="'raw-ftth-'+index">
                    <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <pre>{{ item.indirizzoCensito }}</pre>
                          <pre>{{ item.ftth }}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card me-md-4" v-else>
                <div class="card-header">
                  DSL
                </div>
                <div class="card-body">
                  <div class="card-text">
                    <button data-bs-toggle="modal" :data-bs-target="'#collapse-dsl-'+index" class="m-2 btn btn-primary">Altre info
                    </button>
                    <button data-bs-toggle="modal" :data-bs-target="'#raw-dsl-'+index" class="m-2 btn btn-warning">Raw data</button>
                    <div class="modal" :id="'collapse-dsl-'+index">
                      <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                        <div class="modal-content">
                          <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            <p class="card-text">
                              COORDINATE EDIFICIO:
                              <a class="link-primary" target="_blank"
                                :href="'https://www.google.com/maps/search/?api=1&query='+ item.indirizzoCensito.latitudineCivico+','+item.indirizzoCensito.longitudineCivico">
                              {{ item.indirizzoCensito.latitudineCivico + ' ' + item.indirizzoCensito.longitudineCivico }}</a>
                            </p>
                            <p class="card-text">CODICE EDIFICIO TIM:
                              {{ item.indirizzoCensito.codiceToponomasticoTelecom }}
                            </p>
                            <p class="card-text">TIPO DISTIBUTORE:
                              {{ item.indirizzoCensito.terminazioneTelecom.tipoTerminazione }}
                            </p>
                            <p class="card-text">CODICE DISTIBUTORE TIM:
                              {{ item.indirizzoCensito.terminazioneTelecom.codiceTerminazioneTelecom }}
                            </p>
                            <p class="card-text">
                              CIRCUITO DISTRIBUTORE:
                            <ul>
                              <li v-for="(it, i) in item.indirizzoCensito.terminazioneTelecom.circuitoDistributore"
                                :key="'dist-'+index+'-'+i"> {{ it }}
                              </li>
                            </ul>
                            </p>
                            <p class="card-text">INDIRIZZO DISTIBUTORE:
                              {{ item.indirizzoCensito.terminazioneTelecom.indirizzoDistributore.via }}
                              {{ item.indirizzoCensito.terminazioneTelecom.indirizzoDistributore.civico }}
                              {{ item.indirizzoCensito.terminazioneTelecom.indirizzoDistributore.nomeComune }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal" :id="'raw-dsl-'+index">
                      <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                        <div class="modal-content">
                          <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            <pre>{{ item.indirizzoCensito }}</pre>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul class="list-group list-group-horizontal-md">
                    <li class="list-group-item">
                      <p class="card-text">VDSL: {{ item.vdsl.stato }} (35b/200M: {{
                        item.vdsl.profilo35B
                        }})
                      </p>
                      <p class="card-text" v-if="item.vdsl.tipoEdr ==='Armadio'">VDSL Erogata da Armadio
                      </p>
                      <p class="card-text" v-else-if="item.vdsl.tipoEdr ==='Area di Centrale'">VDSL Erogata da
                        Centrale (Rete Rigida, solo profilo 8b)
                      </p>
                      <p class="card-text">TIPO EDR: {{ item.vdsl.tipoEdr }}</p>
                      <p class="card-text" v-for="(_, i) in item.vdsl.datiWholesale" :key="i">TIPO:
                        {{ item.vdsl.datiWholesale[i].tipoOnu }}
                      </p>
                      <p class="card-text">DISTANZA: {{ item.vdsl.distanza }} m</p>
                      <p class="card-text">EDR: {{ item.vdsl.edr }}</p>
                      <button data-bs-toggle="modal" :data-bs-target="'#collapse-vdsl-'+index" class="m-2 btn btn-primary">Altre info</button>
                      <button data-bs-toggle="modal" :data-bs-target="'#raw-vdsl-'+index" class="m-2 btn btn-warning">Raw data</button>
                      <div class="modal" :id="'collapse-vdsl-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header">
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <p class="card-text" v-for="(_, i) in item.vdsl.datiWholesale"
                                :key="'info-vdsl-'+index+'-clli-'+i">CLLI:
                                {{ item.vdsl.datiWholesale[i].clliOnu }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.vdsl.armadiAttestati"
                                :key="'info-vdsl-'+index+'-indirizzo-'+i">
                                Indirizzo:
                                {{ item.vdsl.armadiAttestati[i].viaToponomasticaTelecom }},
                                {{ item.vdsl.armadiAttestati[i].civicoToponomasticaTelecom }},
                                {{ item.vdsl.armadiAttestati[i].comuneToponomasticaTelecom }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.vdsl.armadiAttestati"
                                :key="'info-vdsl-'+index+'-codice-top-armadio-'+i">
                                Codice toponomastica armadi TIM:
                                {{ item.vdsl.armadiAttestati[i].codiceToponomasticoTelecom }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.vdsl.armadiAttestati"
                                :key="'info-vdsl-'+index+'-posizione-'+i">
                                Posizione:
                                <a class="link-primary" target="_blank"
                                  :href="'https://www.google.com/maps/search/?api=1&query='+ convertDegreeToDecimal(item.vdsl.armadiAttestati[i].latitudine)+','+ convertDegreeToDecimal(item.vdsl.armadiAttestati[i].longitudine)">
                                {{ convertDegreeToDecimal(item.vdsl.armadiAttestati[i].latitudine) }}
                                {{ convertDegreeToDecimal(item.vdsl.armadiAttestati[i].longitudine) }}</a>
                              </p>
                              <p class="card-text">VELOCITÀ STIMATA 17a (Down / Up):
                                {{ item.vdsl.velocitaStimataDown_17A }} /
                                {{ item.vdsl.velocitaStimataUp_17A }} Mbps
                              </p>
                              <p class="card-text">VELOCITÀ STIMATA 35b (Down / Up):
                                {{ item.vdsl.velocitaStimataDown_35B }} /
                                {{ item.vdsl.velocitaStimataUp_35B }} Mbps
                              </p>
                              <p class="card-text" v-for="(_, i) in item.vdsl.datiWholesale"
                                :key="'info-vdsl-'+index+'-olt-'+i">
                                SEDE OLT:
                                {{ item.vdsl.datiWholesale[i].nomeSedeOlt }} (CLLI:
                                {{ item.vdsl.datiWholesale[i].clliSedeOlt }}, Regione:
                                {{ item.vdsl.datiWholesale[i].regioneOlt }}, Comune:
                                {{ item.vdsl.datiWholesale[i].comuneOlt }}, Indirizzo:
                                {{ item.vdsl.centraleSedeOlt.indirizzo }},
                                <a class="link-primary" target="_blank"
                                  :href="'https://www.google.com/maps/search/?api=1&query='+ item.vdsl.centraleSedeOlt.latitudine+','+ item.vdsl.centraleSedeOlt.longitudine">
                                {{ item.vdsl.centraleSedeOlt.latitudine }}
                                {{ item.vdsl.centraleSedeOlt.longitudine }}</a>
                                )
                              </p>
                              <p class="card-text" v-for="(_, i) in item.vdsl.datiWholesale"
                                :key="'info-vdsl-'+index+'-aperturaServizi-'+i">APERTURA
                                SERVIZI:
                                {{ item.vdsl.datiWholesale[i].dataComunicazioneAperturaServizi }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.vdsl.datiWholesale"
                                :key="'info-vdsl-'+index+'-pianificazione-'+i">
                                PIANIFICAZIONE:
                                {{ item.vdsl.datiWholesale[i].dataPianificazioneOnu }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.vdsl.datiWholesale"
                                :key="'info-vdsl-'+index+'-note-'+i">NOTE:
                                {{ item.vdsl.datiWholesale[i].note }}
                              </p>
                              <p class="card-text">MACROAREA: {{ item.vdsl.macroArea }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal" :id="'raw-vdsl-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header">
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <pre>{{ item.vdsl }}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <p class="card-text">SLU: {{ item.slu.stato }}
                      </p>
                      <p class="card-text">TIPO EDR: {{ item.slu.tipoEdr }}</p>
                      <p class="card-text">DISTANZA: {{ item.slu.distanza }} m</p>
                      <p class="card-text">EDR: {{ item.slu.edr }}</p>
                      <p class="card-text">ARMADIO PADRE: {{ item.slu.armadioPadre }}</p>
                      <button data-bs-toggle="modal" :data-bs-target="'#collapse-slu-'+index" class="m-2 btn btn-primary">Altre info</button>
                      <button data-bs-toggle="modal" :data-bs-target="'#raw-slu-'+index" class="m-2 btn btn-warning">Raw data</button>
                      <div class="modal" :id="'collapse-slu-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header">
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <p class="card-text">VELOCITÀ STIMATA 17a (Down / Up):
                                {{ item.slu.velocitaStimataDown_17A }} /
                                {{ item.slu.velocitaStimataUp_17A }} Mbps
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal" :id="'raw-slu-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header">
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <pre>{{ item.slu }}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <p class="card-text">ADSL ATM: {{ item.adslAtm.stato }}</p>
                      <p class="card-text" v-if="item.adslAtm.tipoEdr ==='Apparato con DSLAM'">ADSL Erogata da
                        DSALM su MUX/SAS
                      </p>
                      <p class="card-text" v-else-if="item.adslAtm.tipoEdr ==='Apparato MSAN'">ADSL Erogata da
                        MSAN
                      </p>
                      <p class="card-text" v-else-if="item.adslAtm.tipoEdr ==='Centrale'">ADSL Erogata da
                        Centrale
                      </p>
                      <p class="card-text">TIPO EDR: {{ item.adslAtm.tipoEdr }}</p>
                      <p class="card-text">DISTANZA: {{ item.adslAtm.distanza }} m</p>
                      <p class="card-text">EDR: {{ item.adslAtm.edr }}</p>
                      <button data-bs-toggle="modal" :data-bs-target="'#collapse-adslAtm-'+index" class="m-2 btn btn-primary">Altre info</button>
                      <button data-bs-toggle="modal" :data-bs-target="'#raw-adslAtm-'+index" class="m-2 btn btn-warning">Raw data</button>
                      <div class="modal" :id="'collapse-adslAtm-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header">
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <p class="card-text" v-for="(_, i) in item.adslAtm.centraliAttestate"
                                :key="'info-adslAtm-'+index+'-clli-'+i">CLLI:
                                {{ item.adslAtm.centraliAttestate[i].clli }}, Nome area
                                centrale: {{ item.adslAtm.centraliAttestate[i].nomeAreaCentrale }}, Nome
                                Gat: {{ item.adslAtm.centraliAttestate[i].nomeGat }}, Gat:
                                {{ item.adslAtm.centraliAttestate[i].gat }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslAtm.centraliAttestate"
                                :key="'info-adslAtm-'+index+'-centrale-'+i">ACL
                                Centrale: {{
                                item.adslAtm.centraliAttestate[i].aclCentrale
                                }}, ACL Area Centrale:
                                {{ item.adslAtm.centraliAttestate[i].acl_area_centrale }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslAtm.centraliAttestate"
                                :key="'info-adslAtm-'+index+'-indirizzo-'+i">
                                Indirizzo:
                                {{ item.adslAtm.centraliAttestate[i].indirizzoCentrale }},
                                {{ item.adslAtm.centraliAttestate[i].comuneCentrale }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslAtm.centraliAttestate"
                                :key="'info-adslAtm-'+index+'-posizione-'+i">
                                Posizione:
                                <a class="link-primary" target="_blank"
                                  :href="'https://www.google.com/maps/search/?api=1&query='+  item.adslAtm.centraliAttestate[i].latitudine.replace(',','.')+','+  item.adslAtm.centraliAttestate[i].longitudine.replace(',','.')">
                                {{ item.adslAtm.centraliAttestate[i].latitudine.replace(',', '.') }}
                                {{ item.adslAtm.centraliAttestate[i].longitudine.replace(',', '.') }}</a>
                                , Certificate:
                                {{ item.adslAtm.centraliAttestate[i].certificazioneCoordinate }}
                              </p>
                              <p class="card-text">VELOCITÀ STIMATA 6dB (Down / Up):
                                {{ item.adslAtm.velocitaStimataDown_6db }} /
                                {{ item.adslAtm.velocitaStimataUp_6db }} Mbps
                              </p>
                              <p class="card-text">VELOCITÀ STIMATA 11dB (Down / Up):
                                {{ item.adslAtm.velocitaStimataDown_11db }} /
                                {{ item.adslAtm.velocitaStimataUp_11db }} Mbps
                              </p>
                              <p class="card-text">VELOCITÀ STIMATA 12dB (Down / Up):
                                {{ item.adslAtm.velocitaStimataDown_12db }} /
                                {{ item.adslAtm.velocitaStimataUp_12db }} Mbps
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslAtm.centraliAttestate"
                                :key="'info-adslAtm-'+index+'-sgu-'+i">SEDE
                                SGU: {{ item.adslAtm.centraliAttestate[i].sedeSgu }}, 
                                NOME SGU: {{ item.adslAtm.centraliAttestate[i].nomeSgu }}, 
                                CLLI SGU: {{ item.adslAtm.centraliAttestate[i].clliSgu }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslAtm.centraliAttestate"
                                :key="'info-adslAtm-'+index+'-ntr-'+i">SEDE
                                NTR: {{ item.adslAtm.centraliAttestate[i].sedeNtr }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslAtm.centraliAttestate"
                                :key="'info-adslAtm-'+index+'-idbre-'+i">
                                IDBRE: Sede {{ item.adslAtm.centraliAttestate[i].idbreSede }},
                                Area Centrale {{ item.adslAtm.centraliAttestate[i].idbreAreaCentrale }},
                                Sgu {{ item.adslAtm.centraliAttestate[i].idbreSgu }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslAtm.centraliAttestate"
                                :key="'info-adslAtm-'+index+'-dismissione-'+i">
                                DISMISSIONE: {{ item.adslAtm.centraliAttestate[i].dismissione }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslAtm.centraliAttestate"
                                :key="'info-adslAtm-'+index+'-note-'+i">NOTE:
                                {{ item.adslAtm.centraliAttestate[i].note }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal" :id="'raw-adslAtm-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header">
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <pre>{{ item.adslAtm }}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <p class="card-text">ADSL ETH: {{ item.adslEth.stato }}</p>
                      <p class="card-text" v-if="item.adslEth.tipoEdr ==='Apparato con DSLAM'">ADSL Erogata da DSALM su MUX/SAS</p>
                      <p class="card-text" v-else-if="item.adslEth.tipoEdr ==='Apparato MSAN'">ADSL Erogata da MSAN</p>
                      <p class="card-text" v-else-if="item.adslEth.tipoEdr ==='Centrale'">ADSL Erogata da Centrale</p>
                      <p class="card-text">TIPO EDR: {{ item.adslEth.tipoEdr }}</p>
                      <p class="card-text">DISTANZA: {{ item.adslEth.distanza }} m</p>
                      <p class="card-text">EDR: {{ item.adslEth.edr }}</p>
                      <button data-bs-toggle="modal" :data-bs-target="'#collapse-adslEth-'+index" class="m-2 btn btn-primary">Altre info</button>
                      <button data-bs-toggle="modal" :data-bs-target="'#raw-adslEth-'+index" class="m-2 btn btn-warning">Raw data</button>
                      <div class="modal" :id="'collapse-adslEth-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header">
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <p class="card-text" v-for="(_, i) in item.adslEth.centraliAttestate"
                                :key="'info-adslEth-'+index+'-clli-'+i">CLLI:
                                {{ item.adslEth.centraliAttestate[i].clli }}, Nome area
                                centrale: {{ item.adslEth.centraliAttestate[i].nomeAreaCentrale }}, Nome
                                Gat: {{ item.adslEth.centraliAttestate[i].nomeGat }}, Gat:
                                {{ item.adslEth.centraliAttestate[i].gat }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslEth.centraliAttestate"
                                :key="'info-adslEth-'+index+'-centrale-'+i">ACL
                                Centrale: {{
                                item.adslEth.centraliAttestate[i].aclCentrale
                                }}, ACL Area Centrale:
                                {{ item.adslEth.centraliAttestate[i].acl_area_centrale }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslEth.centraliAttestate"
                                :key="'info-adslEth-'+index+'-indirizzo-'+i">
                                Indirizzo:
                                {{ item.adslEth.centraliAttestate[i].indirizzoCentrale }},
                                {{ item.adslEth.centraliAttestate[i].comuneCentrale }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslEth.centraliAttestate"
                                :key="'info-adslEth-'+index+'-posizione-'+i">
                                Posizione:
                                <a class="link-primary" target="_blank"
                                  :href="'https://www.google.com/maps/search/?api=1&query='+  item.adslEth.centraliAttestate[i].latitudine.replace(',','.')+','+  item.adslEth.centraliAttestate[i].longitudine.replace(',','.')">
                                {{ item.adslEth.centraliAttestate[i].latitudine.replace(',', '.') }}
                                {{ item.adslEth.centraliAttestate[i].longitudine.replace(',', '.') }}</a>
                                , Certificate:
                                {{ item.adslEth.centraliAttestate[i].certificazioneCoordinate }}
                              </p>
                              <p class="card-text">VELOCITÀ STIMATA 6dB (Down / Up):
                                {{ item.adslEth.velocitaStimataDown_6db }} /
                                {{ item.adslEth.velocitaStimataUp_6db }} Mbps
                              </p>
                              <p class="card-text">VELOCITÀ STIMATA 11dB (Down / Up):
                                {{ item.adslEth.velocitaStimataDown_11db }} /
                                {{ item.adslEth.velocitaStimataUp_11db }} Mbps
                              </p>
                              <p class="card-text">VELOCITÀ STIMATA 12dB (Down / Up):
                                {{ item.adslEth.velocitaStimataDown_12db }} /
                                {{ item.adslEth.velocitaStimataUp_12db }} Mbps
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslEth.centraliAttestate"
                                :key="'info-adslEth-'+index+'-sgu-'+i">
                                SEDE SGU: {{ item.adslEth.centraliAttestate[i].sedeSgu }}, 
                                NOME SGU: {{ item.adslEth.centraliAttestate[i].nomeSgu }}, 
                                CLLI SGU: {{ item.adslEth.centraliAttestate[i].clliSgu }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslEth.centraliAttestate"
                                :key="'info-adslEth-'+index+'-ntr-'+i">
                                SEDE NTR: {{ item.adslEth.centraliAttestate[i].sedeNtr }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslEth.centraliAttestate"
                                :key="'info-adslEth-'+index+'-idbre-'+i">
                                IDBRE: Sede {{ item.adslEth.centraliAttestate[i].idbreSede }},
                                Area Centrale {{ item.adslEth.centraliAttestate[i].idbreAreaCentrale }},
                                Sgu {{ item.adslEth.centraliAttestate[i].idbreSgu }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslEth.centraliAttestate"
                                :key="'info-adslEth-'+index+'-aperturaservizi-'+i">
                                APERTURA SERVIZI:
                                {{ item.adslEth.datiWholesale[i].dataComunicazioneAperturaServizi }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslEth.centraliAttestate"
                                :key="'info-adslEth-'+index+'-dismissione-'+i">
                                DISMISSIONE: {{
                                item.adslEth.centraliAttestate[i].dismissione
                                }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslEth.centraliAttestate"
                                :key="'info-adslEth-'+index+'-centrali-'+i">NOTE
                                Centrali: {{ item.adslEth.centraliAttestate[i].note }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslEth.datiWholesale"
                                :key="'info-adslEth-'+index+'-note-'+i">NOTE
                                Wholesale:
                                {{ item.adslEth.datiWholesale[i].note }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslEth.datiWholesale"
                                :key="'info-adslEth-'+index+'-preordine-'+i">
                                VLAN: {{ item.adslEth.datiWholesale[i].supportoVlanSpeciali }},
                                preordine: {{ item.adslEth.datiWholesale[i].preOrdineVlan }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslEth.datiWholesale"
                                :key="'raw-adslEth-'+index+'-feeder-'+i">
                                FEEDER: {{ item.adslEth.datiWholesale[i].clliSedeFeeder }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.adslEth.datiWholesale"
                                :key="'info-adslEth-'+index+'-tecnologia-'+i">
                                TECNOLOGIA: {{ item.adslEth.datiWholesale[i].tecnologia }}
                                {{ item.adslEth.datiWholesale[i].tipoDslam }}
                              </p>
                              <p class="card-text">MACROAREA: {{ item.vdsl.macroArea }}</p>
                              <!--<p class="card-text" v-for="(_, i) in item.adslEth.datiWholesale" :key="i">PIANIFICAZIONE: {{
                                item.vdsl.datiWholesale[i].pianificazione
                                }}
                                </p>-->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal" :id="'raw-adslEth-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header">
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <pre>{{ item.adslEth }}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <p class="card-text">ULL: {{ item.ull.stato }}</p>
                      <p class="card-text">TIPO EDR: {{ item.ull.tipoEdr }}</p>
                      <p class="card-text">EDR: {{ item.ull.edr }}</p>
                      <p class="card-text">DISTANZA: {{ item.ull.distanza }} m</p>
                      <button data-bs-toggle="modal" :data-bs-target="'#collapse-ull-'+index" class="m-2 btn btn-primary">Altre info</button>
                      <button data-bs-toggle="modal" :data-bs-target="'#raw-ull-'+index" class="m-2 btn btn-warning">Raw data</button>
                      <div class="modal" :id="'collapse-ull-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header">
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <p class="card-text" v-for="(_, i) in item.ull.centraliAttestate"
                                :key="'info-ull-'+index+'-clli-'+i">
                                CLLI: {{ item.ull.centraliAttestate[i].clli_centrale }},
                                Nome Gat: {{ item.ull.centraliAttestate[i].nome_gat }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.ull.centraliAttestate"
                                :key="'info-ull-'+index+'-acl-'+i">
                                ACL Area Centrale: {{ item.ull.centraliAttestate[i].acl_area_centrale }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.ull.centraliAttestate"
                                :key="'info-ull-'+index+'-indirizzo-'+i">
                                Indirizzo:
                                {{ item.ull.centraliAttestate[i].indirizzo }},
                                {{ item.ull.centraliAttestate[i].comune }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.ull.centraliAttestate"
                                :key="'info-ull-'+index+'-posizione-'+i">
                                Posizione:
                                <a class="link-primary" target="_blank"
                                  :href="'https://www.google.com/maps/search/?api=1&query='+ item.ull.centraliAttestate[i].latitudine+','+ item.ull.centraliAttestate[i].longitudine">
                                {{ item.ull.centraliAttestate[i].latitudine }}
                                {{ item.ull.centraliAttestate[i].longitudine }}</a>
                              </p>
                              <p class="card-text" v-for="(_, i) in item.ull.centraliAttestate"
                                :key="'info-ull-'+index+'-note-'+i">
                                Note: {{ item.ull.centraliAttestate[i].note }}
                              </p>
                              <p class="card-text">VELOCITÀ STIMATA ADSL (Down / Up):
                                {{ item.ull.velocitaStimataDown_Adsl }} /
                                {{ item.ull.velocitaStimataUp_Adsl }} Mbps
                              </p>
                              <p class="card-text">VELOCITÀ STIMATA VDSL (Down / Up):
                                {{ item.ull.velocitaStimataDown_Vdsl }} /
                                {{ item.ull.velocitaStimataUp_Vdsl }} Mbps
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal" :id="'raw-ull-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header">
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <pre>{{ item.ull }}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul class="list-group list-group-horizontal-md mt-4">
                    <li class="list-group-item">
                      <p class="card-text">WLR: {{ item.wlr.stato }}</p>
                      <p class="card-text" v-if="item.wlr.tipoEdr ==='Apparato'">WLR Erogato da MUX/UCR
                      </p>
                      <p class="card-text" v-else-if="item.wlr.tipoEdr ==='Apparato con DSLAM'">WLR Erogato da
                        DSALM su MUX/SAS
                      </p>
                      <p class="card-text" v-else-if="item.wlr.tipoEdr ==='Apparato MSAN'">WLR Erogato da MSAN
                      </p>
                      <p class="card-text" v-else-if="item.wlr.tipoEdr ==='Centrale'">WLR Erogato da Centrale
                      </p>
                      <p class="card-text">TIPO EDR: {{ item.wlr.tipoEdr }}</p>
                      <p class="card-text">EDR: {{ item.wlr.edr }}</p>
                      <p class="card-text">DISTANZA: {{ item.wlr.distanza }} m</p>
                      <button data-bs-toggle="modal" :data-bs-target="'#collapse-wlr-'+index" class="m-2 btn btn-primary">Altre info</button>
                      <button data-bs-toggle="modal" :data-bs-target="'#raw-wlr-'+index" class="m-2 btn btn-warning">Raw data</button>
                      <div class="modal" :id="'collapse-wlr-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                            <div class="modal-body">
                              <p class="card-text" v-for="(_, i) in item.wlr.centraliAttestate"
                                :key="'info-wlr-'+index+'-clli-'+i">
                                CLLI: {{ item.wlr.centraliAttestate[i].clli_centrale }},
                                Nome Gat: {{ item.wlr.centraliAttestate[i].nome_gat }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.wlr.centraliAttestate"
                                :key="'info-wlr-'+index+'-acl-'+i">
                                ACL Area Centrale: {{ item.wlr.centraliAttestate[i].acl_area_centrale }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.wlr.centraliAttestate"
                                :key="'info-wlr-'+index+'-indirizzo-'+i">
                                Indirizzo:
                                {{ item.wlr.centraliAttestate[i].indirizzo }},
                                {{ item.wlr.centraliAttestate[i].comune }}
                              </p>
                              <p class="card-text" v-for="(_, i) in item.wlr.centraliAttestate"
                                :key="'info-wlr-'+index+'-posizione-'+i">
                                Posizione:
                                <a class="link-primary" target="_blank"
                                  :href="'https://www.google.com/maps/search/?api=1&query='+ item.wlr.centraliAttestate[i].latitudine+','+ item.wlr.centraliAttestate[i].longitudine">
                                {{ item.wlr.centraliAttestate[i].latitudine }}
                                {{ item.wlr.centraliAttestate[i].longitudine }}</a>
                              </p>
                              <p class="card-text" v-for="(_, i) in item.wlr.centraliAttestate"
                                :key="'info-wlr-'+index+'-note-'+i">
                                Note: {{ item.wlr.centraliAttestate[i].note }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal" :id="'raw-wlr-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                            <div class="modal-body">
                              <pre>{{ item.wlr }}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <p class="card-text">SHDSL ATM 4M: {{ item.shdslAtm_4M.stato }}</p>
                      <p class="card-text">TIPO EDR: {{ item.shdslAtm_4M.tipoEdr }}</p>
                      <p class="card-text">EDR: {{ item.shdslAtm_4M.edr }}</p>
                      <p class="card-text">DISTANZA: {{ item.shdslAtm_4M.distanza }} m</p>
                      <button data-bs-toggle="modal" :data-bs-target="'#collapse-shdsl-atm-4-'+index" class="m-2 btn btn-primary">
                      Altre info</button>
                      <button data-bs-toggle="modal" :data-bs-target="'#raw-shdsl-atm-4-'+index" class="m-2 btn btn-warning">Raw
                      data
                      </button>
                      <div class="modal" :id="'collapse-shdsl-atm-4-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                            <div class="modal-body">
                              <p class="card-text" v-if="item.shdslAtm_4M.centraleAttestazione">
                                CLLI: {{ item.shdslAtm_4M.centraleAttestazione.clli_centrale }},
                                Nome Gat: {{ item.shdslAtm_4M.centraleAttestazione.nome_gat }}
                              </p>
                              <p class="card-text" v-if="item.shdslAtm_4M.centraleAttestazione">
                                ACL Area Centrale: {{ item.shdslAtm_4M.centraleAttestazione.acl_area_centrale }}
                              </p>
                              <p class="card-text" v-if="item.shdslAtm_4M.centraleAttestazione">
                                Indirizzo:
                                {{ item.shdslAtm_4M.centraleAttestazione.indirizzo }},
                                {{ item.shdslAtm_4M.centraleAttestazione.comune }}
                              </p>
                              <p class="card-text" v-if="item.shdslAtm_4M.centraleAttestazione">
                                Posizione:
                                <a class="link-primary" target="_blank"
                                  :href="'https://www.google.com/maps/search/?api=1&query='+ item.shdslAtm_4M.centraleAttestazione.latitudine+','+ item.shdslAtm_4M.centraleAttestazione.longitudine">
                                {{ item.shdslAtm_4M.centraleAttestazione.latitudine }}
                                {{ item.shdslAtm_4M.centraleAttestazione.longitudine }}</a>
                              </p>
                              <p class="card-text" v-if="item.shdslAtm_4M.centraleAttestazione">
                                Note: {{ item.shdslAtm_4M.centraleAttestazione.note }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal" :id="'raw-shdsl-atm-4-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                            <div class="modal-body">
                              <pre>{{ item.shdslAtm_4M }}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <p class="card-text">SHDSL ATM 8M: {{ item.shdslAtm_8M.stato }}</p>
                      <p class="card-text">TIPO EDR: {{ item.shdslAtm_8M.tipoEdr }}</p>
                      <p class="card-text">EDR: {{ item.shdslAtm_8M.edr }}</p>
                      <p class="card-text">DISTANZA: {{ item.shdslAtm_8M.distanza }} m</p>
                      <button data-bs-toggle="modal" :data-bs-target="'#collapse-shdsl-atm-8-'+index" class="m-2 btn btn-primary">
                      Altre info</button>
                      <button data-bs-toggle="modal" :data-bs-target="'#raw-shdsl-atm-8-'+index" class="m-2 btn btn-warning">Raw
                      data
                      </button>
                      <div class="modal" :id="'collapse-shdsl-atm-8-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                            <div class="modal-body">
                              <p class="card-text" v-if="item.shdslAtm_8M.centraleAttestazione">
                                CLLI: {{ item.shdslAtm_8M.centraleAttestazione.clli_centrale }},
                                Nome Gat: {{ item.shdslAtm_8M.centraleAttestazione.nome_gat }}
                              </p>
                              <p class="card-text" v-if="item.shdslAtm_8M.centraleAttestazione">
                                ACL Area Centrale: {{ item.shdslAtm_8M.centraleAttestazione.acl_area_centrale }}
                              </p>
                              <p class="card-text" v-if="item.shdslAtm_8M.centraleAttestazione">
                                Indirizzo:
                                {{ item.shdslAtm_8M.centraleAttestazione.indirizzo }},
                                {{ item.shdslAtm_8M.centraleAttestazione.comune }}
                              </p>
                              <p class="card-text" v-if="item.shdslAtm_8M.centraleAttestazione">
                                Posizione:
                                <a class="link-primary" target="_blank"
                                  :href="'https://www.google.com/maps/search/?api=1&query='+ item.shdslAtm_8M.centraleAttestazione.latitudine+','+ item.shdslAtm_8M.centraleAttestazione.longitudine">
                                {{ item.shdslAtm_8M.centraleAttestazione.latitudine }}
                                {{ item.shdslAtm_8M.centraleAttestazione.longitudine }}</a>
                              </p>
                              <p class="card-text" v-if="item.shdslAtm_8M.centraleAttestazione">
                                Note: {{ item.shdslAtm_8M.centraleAttestazione.note }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal" :id="'raw-shdsl-atm-8-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                            <div class="modal-body">
                              <pre>{{ item.shdslAtm_8M }}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <p class="card-text">SHDSL ETH 10M: {{ item.shdslEth_10M.stato }}</p>
                      <p class="card-text">TIPO EDR: {{ item.shdslEth_10M.tipoEdr }}</p>
                      <p class="card-text">EDR: {{ item.shdslEth_10M.edr }}</p>
                      <p class="card-text">DISTANZA: {{ item.shdslEth_10M.distanza }} m</p>
                      <button data-bs-toggle="modal" :data-bs-target="'#collapse-shdsl-eth-10-'+index" class="m-2 btn btn-primary">
                      Altre info</button>
                      <button data-bs-toggle="modal" :data-bs-target="'#raw-shdsl-eth-10-'+index" class="m-2 btn btn-warning">Raw
                      data
                      </button>
                      <div class="modal" :id="'collapse-shdsl-eth-10-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                            <div class="modal-body">
                              <p class="card-text" v-if="item.shdslEth_10M.centraleAttestazione">
                                CLLI: {{ item.shdslEth_10M.centraleAttestazione.clli_centrale }},
                                Nome Gat: {{ item.shdslEth_10M.centraleAttestazione.nome_gat }}
                              </p>
                              <p class="card-text" v-if="item.shdslEth_10M.centraleAttestazione">
                                ACL Area Centrale: {{ item.shdslEth_10M.centraleAttestazione.acl_area_centrale }}
                              </p>
                              <p class="card-text" v-if="item.shdslEth_10M.centraleAttestazione">
                                Indirizzo:
                                {{ item.shdslEth_10M.centraleAttestazione.indirizzo }},
                                {{ item.shdslEth_10M.centraleAttestazione.comune }}
                              </p>
                              <p class="card-text" v-if="item.shdslEth_10M.centraleAttestazione">
                                Posizione:
                                <a class="link-primary" target="_blank"
                                  :href="'https://www.google.com/maps/search/?api=1&query='+ item.shdslEth_10M.centraleAttestazione.latitudine+','+ item.shdslEth_10M.centraleAttestazione.longitudine">
                                {{ item.shdslEth_10M.centraleAttestazione.latitudine }} 
                                {{ item.shdslEth_10M.centraleAttestazione.longitudine }}</a>
                              </p>
                              <p class="card-text" v-if="item.shdslEth_10M.centraleAttestazione">
                                Note: {{ item.shdslEth_10M.centraleAttestazione.note }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal" :id="'raw-shdsl-eth-10-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                            <div class="modal-body">
                              <pre>{{ item.shdslEth_10M }}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <p class="card-text">FIBRA ILC: {{ item.fibraIlc.stato }}</p>
                      <p class="card-text">TIPO EDR: {{ item.fibraIlc.tipoEdr }}</p>
                      <p class="card-text">EDR: {{ item.fibraIlc.edr }}</p>
                      <p class="card-text">FASCIA: {{ item.fibraIlc.fascia }}</p>
                      <button data-bs-toggle="modal" :data-bs-target="'#collapse-ilc-'+index" class="m-2 btn btn-primary">Altre info</button>
                      <button data-bs-toggle="modal" :data-bs-target="'#raw-ilc-'+index" class="m-2 btn btn-warning">Raw data</button>
                      <div class="modal" :id="'collapse-ilc-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                            <div class="modal-body">
                              <p class="card-text" v-if="item.fibraIlc.centraleAttestazioneP2P">
                                CLLI: {{ item.fibraIlc.centraleAttestazioneP2P.clli_centrale }},
                                Nome Gat: {{ item.fibraIlc.centraleAttestazioneP2P.nome_gat }}
                              </p>
                              <p class="card-text" v-if="item.fibraIlc.centraleAttestazioneP2P">
                                ACL Area Centrale: {{ item.fibraIlc.centraleAttestazioneP2P.acl_area_centrale }}
                              </p>
                              <p class="card-text" v-if="item.fibraIlc.centraleAttestazioneP2P">
                                Indirizzo:
                                {{ item.fibraIlc.centraleAttestazioneP2P.indirizzo }},
                                {{ item.fibraIlc.centraleAttestazioneP2P.comune }}
                              </p>
                              <p class="card-text" v-if="item.fibraIlc.centraleAttestazioneP2P">
                                Posizione:
                                <a class="link-primary" target="_blank"
                                  :href="'https://www.google.com/maps/search/?api=1&query='+ item.fibraIlc.centraleAttestazioneP2P.latitudine+','+ item.fibraIlc.centraleAttestazioneP2P.longitudine">
                                {{ item.fibraIlc.centraleAttestazioneP2P.latitudine }}
                                {{ item.fibraIlc.centraleAttestazioneP2P.longitudine }}</a>
                              </p>
                              <p class="card-text" v-if="item.fibraIlc.centraleAttestazioneP2P">
                                Note: {{ item.fibraIlc.centraleAttestazioneP2P.note }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal" :id="'raw-ilc-'+index">
                        <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                            <div class="modal-body">
                              <pre>{{ item.fibraIlc }}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div v-for="(item, index) in copertura.fastwebEntries" :key="'fastweb-'+index" class="mt-4">
              <div class="card me-md-4">
                <div class="card-header">
                  Fastweb
                </div>
                <div class="card-body">
                  <p class="card-text">SERVIZIO: {{ item.typeService }}</p>
                  <p class="card-text">PROFILO: {{ item.profile }}</p>
                  <p class="card-text">VELOCITÀ STIMATA (Down / Up):
                    {{ item.downloadSpeed }} /
                    {{ item.uploadSpeed }} Mbps
                  </p>
                  <p class="card-text">DISTANZA: {{ item.distance }}</p>
                  <button class="m-2" data-bs-toggle="modal" :data-bs-target="'#raw-fastweb-'+index" variant="warning">Raw data</button>
                  <div class="modal" :id="'raw-fastweb-'+index">
                    <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <pre>{{ item }}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="(item, index) in copertura.eolo" :key="'eolo-'+index" class="mt-4">
              <div class="card me-md-4">
                <div class="card-header">
                  EOLO
                </div>
                <div class="card-body">
                  <p class="card-text">TECNOLOGIA: {{ item.tecnologia }}</p>
                  <p class="card-text">NOME IMPIANTO: {{ item.nome }}</p>
                  <p class="card-text">DISTANZA: {{ item.distanza }}m</p>
                  <p class="card-text">SEGNALE: da {{ item.segnale_max }}dBm a {{ item.segnale_min }}dBm</p>
                  <p class="card-text">ALTITUDINE: {{ item.altitudine }}</p>
                  <p class="card-text">AZIMUTH: {{ item.azimuth }}</p>
                  <p class="card-text">ELEVAZIONE: {{ item.elevazione }}</p>
                  <p class="card-text">TILT: {{ item.tilt }}</p>
                  <p class="card-text">
                    Posizione:
                    <a class="link-primary" target="_blank" :href="'https://www.google.com/maps/search/?api=1&query='+ item.lat+','+ item.lng">
                    {{ item.lat }} {{ item.lng }}</a>
                  </p>
                  <button data-bs-toggle="modal" :data-bs-target="'#raw-eolo-'+index" class="m-2 btn btn-warning">Raw data</button>
                  <div class="modal" :id="'raw-eolo-'+index">
                    <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <pre>{{ item }}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4" v-if="copertura.fwaTim !== null">
              <div class="card">
                <div class="card-header">
                  FWA TIM
                </div>
                <div class="card-body">
                  <p class="card-text">COPERTURA FWA: {{ copertura.fwaTim.coperturaFwa }}</p>
                  <p class="card-text">COPERTURA FWA 100M: {{ copertura.fwaTim.coperturaFwa100 }}</p>
                  <p class="card-text">COPERTURA FWA RICARICABILE: {{ copertura.fwaTim.coperturaFwaRicaricabile }}</p>
                  <button data-bs-toggle="modal" data-bs-target="#raw-fwaTim" class="m-2 btn btn-warning">Raw data</button>
                  <div class="modal" id="raw-fwaTim">
                    <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <pre>{{ copertura.fwaTim }}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4" v-if="copertura.fwaVodafone !== null">
              <div class="card">
                <div class="card-header">
                  FWA Vodafone
                </div>
                <div class="card-body">
                  <p class="card-text">COPERTURA 4G MOBILE: {{ copertura.fwaVodafone.coperturaLte }}</p>
                  <p class="card-text">COPERTURA FWA: {{ copertura.fwaVodafone.coperturaFwaLte }}</p>
                  <p class="card-text">COPERTURA FWA OUTDOOR: {{ copertura.fwaVodafone.coperturaFwaLteOutdoor }}</p>
                  <p class="card-text">COPERTURA FWA 5G OUTDOOR: {{ copertura.fwaVodafone.coperturaFwa5GOutdoor }}</p>
                  <button data-bs-toggle="modal" data-bs-target="#raw-fwaVodafone" class="m-2 btn btn-warning">Raw data</button>
                  <div class="modal" id="raw-fwaVodafone">
                    <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <pre>{{ copertura.fwaVodafone }}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4" v-if="copertura.fwaWindTre !== null">
              <div class="card">
                <div class="card-header">
                  FWA WindTre
                </div>
                <div class="card-body">
                  <p class="card-text">COPERTURA FWA: {{ copertura.fwaWindTre.copertura }}</p>
                  <button data-bs-toggle="modal" data-bs-target="#raw-fwaWindTre" class="m-2 btn btn-warning">Raw data</button>
                  <div class="modal" id="raw-fwaWindTre">
                    <div class="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <pre>{{ copertura.fwaWindTre }}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-group" v-else>
            <div class="card">
              <div class="card-body">Caricamento completato. Apri la mappa o i raw data</div>
            </div>
          </div>
          <div class="mt-4"></div>
        </div>
      </div>
      <div class="container" v-else>
        <form @submit="onLogin">
          <div class="alert alert-danger" role="alert" v-if="loginerror">Nome utente o password errata.</div>
          <div class="alert alert-danger" role="alert" v-if="backenderror">Il servizio attualmente non è disponibile, riprova più tardi.</div>
          <div class="mb-3">
            Username:
            <input type="text" class="form-control" id="username" v-model="username" required>
          </div>
          <div class="mb-3">
            Password:
            <input type="password" class="form-control" id="password" v-model="password" required>
          </div>
          <button type="submit" class="btn btn-primary">Login</button>
        </form>
      </div>
      <div class="modal" id="raw">
            <div class="modal-dialog modal-fullscreen-xl-down modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <pre>{{ copertura }}</pre>
                </div>
              </div>
            </div>
      </div>
      <div class="modal" id="map" size="xl" ref="map">
      <div class="modal-dialog modal-fullscreen">
          <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
              <l-map :coordinate="coordinate" :center="center" ref="lmap" :cluster="civico?.civico === 'OF_DB_VIA' || via?.nome === 'OF_DB_COMUNE'"></l-map>
          </div>
          </div>
      </div>
      </div>
    </div>
</template>

<script>


import {VueSelect} from 'vue-select';
import _ from 'lodash';

import LMap from './LMap.vue';


export default {
    name: 'App',
    data() {
        return {
            comuni: [],
            comune: null,

            vie: [],
            via: null,

            civici: [],
            civico: null,

            copertura: null,

            username: null,
            password: null,
            login: false,
            loginerror: false,
            backenderror: false,

            loading: false,

            coordinate: [],
            coordinateMap: new Map(),
            center: [],
            coordinateEnabled: false,

            flags: ["netmap","netmap-coordinate-rete","openfiber"],

            error: false
        }
    },
    components: {
        VueSelect, LMap
    },
    methods: {
        onSearchComuni(search, loading) {
            if (search.length) {
                loading(true);
                this.searchComuni(loading, search, this);
            }
        },
        searchComuni: _.debounce(function (loading, search, vm) {

            let headers = new Headers()
            headers.append('Authorization', 'Basic ' + btoa(vm.username + ":" + vm.password));
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            fetch(
                `/api/cerca-comune`,
                {
                    method: 'POST',
                    headers: headers,
                    body: vm.encodeFormData({'comune': search})
                }
            ).then(res => {
                res.json().then(json => (vm.comuni = json.comuni.sort((a, b) => a.nome.localeCompare(b.nome, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                }))));
                loading(false);
            });
        }, 150),
        setComune(value) {
            if (this.comune !== value) {
                this.comune = value;
                this.vie = [];
                this.via = null;
                this.civici = [];
                this.civico = null;
                this.copertura = null;
            }
        },
        onSearchVie(search, loading) {
            if (search.length) {
                loading(true);
                this.searchVie(loading, search, this);
            }
        },
        searchVie: _.debounce(function (loading, search, vm) {
            let headers = new Headers()
            headers.append('Authorization', 'Basic ' + btoa(vm.username + ":" + vm.password));
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            fetch(
                `/api/cerca-via`
                ,
                {
                    method: 'POST',
                    headers: headers,
                    body: vm.encodeFormData({
                        'istat-provincia': vm.comune.idIstatProvincia,
                        'istat-comune': vm.comune.idIstatComune,
                        'via': vm.clearVia(search)
                    })
                }).then(res => {
                res.json().then(json => (vm.vie = json.vie.sort((a, b) => a.nome.localeCompare(b.nome, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                }))));
                loading(false);
            });
        }, 150),
        clearVia(value) {
            return value.toUpperCase()
                .replace("VIA", "")
                .replace("CORSO", "")
                .replace("PIAZZA", "")
                .replace("VICO", "")
                .replace("VICOLO", "")
                .replace("SCALINATA", "")
                .replace("PIAZZALE", "").trim();
        },
        setVia(value) {
            if (this.via !== value) {
                this.via = value;
                this.civici = [];
                this.civico = null;
                this.copertura = null;

                this.searchCivici(this);

            }
        },
        searchCivici: _.debounce(function (vm) {
            let headers = new Headers()
            headers.append('Authorization', 'Basic ' + btoa(vm.username + ":" + vm.password));
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            fetch(
                `/api/cerca-civico`,
                {
                    method: 'POST',
                    headers: headers,
                    body: vm.encodeFormData({
                        'istat-provincia': vm.comune.idIstatProvincia,
                        'istat-comune': vm.comune.idIstatComune,
                        'part-top': vm.via.partTop,
                        'via': vm.via.nome
                    })

                }
            ).then(res => {
                res.json().then(json => (vm.civici = json.civici.sort((a, b) => a.civico.localeCompare(b.civico, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                }))));
            });
        }, 150),
        setCivico(value) {
            this.civico = value;
            this.loading = true;
            this.error = false;
            this.searchServizi(this);
        },
        searchServizi: _.debounce(function (vm) {
            let headers = new Headers()
            headers.append('Authorization', 'Basic ' + btoa(vm.username + ":" + vm.password));
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            fetch(
                `/api/cerca-servizi`
                ,
                {
                    method: 'POST',
                    headers: headers,
                    body: vm.encodeFormDataFlags({
                        'istat-provincia': vm.comune.idIstatProvincia,
                        'istat-comune': vm.comune.idIstatComune,
                        'part-top': vm.via.partTop,
                        'via': vm.via.nome,
                        'civico': vm.civico.civico
                    }, vm.flags)

                }).then(res => {
                res.json().then(json => {
                    vm.copertura = json.servizi;

                    vm.coordinate = [];
                    vm.coordinateMap = new Map();

                    for (const item of json.servizi.netmapEntries) {
                        if (item.indirizzoCensito?.latitudineCivico && item.indirizzoCensito?.longitudineCivico)
                            if (parseFloat(item.indirizzoCensito.latitudineCivico) !== 0 && parseFloat(item.indirizzoCensito.longitudineCivico) !== 0)
                                vm.coordinate.push({
                                    "tipo": "Casa netmap",
                                    "nome": "Coordinate FiberCop",
                                    "lat": parseFloat(item.indirizzoCensito.latitudineCivico),
                                    "lng": parseFloat(item.indirizzoCensito.longitudineCivico),
                                    "info" : {}
                                })

                        if (item.indirizzoCensito?.terminazioneTelecom?.indirizzoDistributore?.latitudine && item.indirizzoCensito?.terminazioneTelecom?.indirizzoDistributore?.longitudine)
                            if (parseFloat(item.indirizzoCensito.terminazioneTelecom.indirizzoDistributore.latitudine) !== 0 && parseFloat(item.indirizzoCensito.terminazioneTelecom.indirizzoDistributore.longitudine) !== 0)
                                vm.coordinate.push({
                                    "tipo": "Distributore",
                                    "nome":  "Distributore",
                                    "lat": parseFloat(item.indirizzoCensito.terminazioneTelecom.indirizzoDistributore.latitudine),
                                    "lng": parseFloat(item.indirizzoCensito.terminazioneTelecom.indirizzoDistributore.longitudine),
                                    "info" : {
                                      "Tipo terminazione" : item.indirizzoCensito.terminazioneTelecom.tipoTerminazione,
                                      "Codice terminazione" : item.indirizzoCensito.terminazioneTelecom.codiceTerminazioneTelecom
                                    }
                                })

                        let armadi = item.vdsl.armadiAttestati.map((it, i) => Object.assign({}, it, item.vdsl.datiWholesale[i]));

                        for (const armadio of armadi) {
                            if (vm.convertDegreeToDecimal(armadio.latitudine) !== 0 && vm.convertDegreeToDecimal(armadio.longitudine) !== 0)
                                this.updateMap(vm.coordinateMap,armadio.acl + "_" + armadio.elemento, {
                                    "tipo": "Armadio",
                                    "nome": "Armadio " + armadio.acl + "_" + armadio.elemento,
                                    "lat": vm.convertDegreeToDecimal(armadio.latitudine),
                                    "lng": vm.convertDegreeToDecimal(armadio.longitudine),
                                    "info" : {
                                      "Indirizzo" : armadio.comuneToponomasticaTelecom + ": " + armadio.partToponomasticaTelecom + " " + armadio.viaToponomasticaTelecom + "->" + armadio.civicoToponomasticaTelecom,
                                      "CLLI ONU" : armadio.clliOnu,
                                      "Tipo ONU" : armadio.tipoOnu,
                                      "VDSL2": armadio.massimaVelocitaVdsl2,
                                      "Stato ONU" : armadio.statoOnu,
                                      "CLLI OLT" : armadio.clliSedeOlt,
                                      "Nome OLT" : armadio.nomeSedeOlt
                                    }
                                })
                        }

                        for (const cnocro of item.ftth.cno_cro) {
                            if (cnocro.elemento_ottico.latitudine !== 0.0 && cnocro.elemento_ottico.longitudine !== 0.0)
                                this.updateMap(vm.coordinateMap, cnocro.elemento_ottico.id_elemento_esteso, {
                                    "tipo": cnocro.elemento_ottico.tipo_elemento,
                                    "nome": cnocro.elemento_ottico.id_elemento_esteso,
                                    "lat": cnocro.elemento_ottico.latitudine,
                                    "lng": cnocro.elemento_ottico.longitudine,
                                    "info" : {
                                      "Tipo elemento" : cnocro.elemento_ottico.tipo_elemento,
                                      "ACL Area di Centrale" : cnocro.elemento_ottico.acl_area_centrale,
                                      "EDR Centrale OLT": cnocro.elemento_ottico.edr_olt_centrale,
                                      "ID elemento" : cnocro.elemento_ottico.id_elemento,
                                      "Indirizzo" : cnocro.elemento_ottico.indirizzo,
                                      "Stato" : cnocro.elemento_ottico.stato,
                                      "Data disponibilità" : cnocro.elemento_ottico.data_disponibilita,
                                      "Data pubblicazione" : cnocro.elemento_ottico.data_pubblicazione
                                    }
                                })
                        }

                        for (const ptefibercop of item.indirizzoCensito.pteFibercop) {
                            if (vm.convertDegreeToDecimal(ptefibercop.latitudine) !== 0 && vm.convertDegreeToDecimal(ptefibercop.longitudine) !== 0) {
                                this.updateMap(vm.coordinateMap, ptefibercop.nomePte, {
                                    "tipo": "PTE FiberCop",
                                    "nome": ptefibercop.nomePte,
                                    "lat": vm.convertDegreeToDecimal(ptefibercop.latitudine),
                                    "lng": vm.convertDegreeToDecimal(ptefibercop.longitudine),
                                    "info" : {
                                        "Stato" : ptefibercop.stato,
                                        "Via" : ptefibercop.via,
                                        "Civico" : ptefibercop.civico,
                                        "ID sito" : ptefibercop.idSito,
                                        "CLLI ARLO" : ptefibercop.clliArmadioOttico,
                                        "ID elemento ARLO" : ptefibercop.idElementoArmadioOttico,
                                        "Nome morsettiera" : ptefibercop.nomeMorsettiera,
                                        "Tipo morsettiera" : ptefibercop.modelloMorsettiera,
                                        "Ubicazione" : ptefibercop.tipoUbicazione,
                                        "Tratta primaria" : ptefibercop.trattaPrimaria,
                                        "Tratta secondaria" : ptefibercop.trattaSecondaria,
                                        "Distributore rame" : ptefibercop.distributoreRameAssociato,
                                        "Impresa" : ptefibercop.impresaCollaudo,
                                        "Avanzamento tecnico" : ptefibercop.avanzamentoTecnico,
                                        "Network ready" : ptefibercop.networkReady,
                                        "Data collaudo" : ptefibercop.dataCollaudoImpresa,
                                        "Data connected" : ptefibercop.dataConnectedPte
                                    }
                                })
                            }
                        }

                        if ((item.vdsl?.stato ?? "NO") !== "NO" && item.vdsl?.centraleSedeOlt?.latitudine && item.vdsl?.centraleSedeOlt?.longitudine)
                            if (parseFloat(item.vdsl.centraleSedeOlt.latitudine) !== 0 && parseFloat(item.vdsl.centraleSedeOlt.longitudine) !== 0)
                                this.updateMap(vm.coordinateMap,item.vdsl.centraleSedeOlt.clli_centrale, {
                                    "tipo": "Centrale",
                                    "nome": "Centrale " + item.vdsl.centraleSedeOlt.clli_centrale,
                                    "lat": parseFloat(item.vdsl.centraleSedeOlt.latitudine),
                                    "lng": parseFloat(item.vdsl.centraleSedeOlt.longitudine),
                                    "info" : {
                                        "ACL Area Centrale" : item.vdsl.centraleSedeOlt.acl_area_centrale,
                                        "VDSL Sede OLT": true,
                                        "Indirizzo" : item.vdsl.centraleSedeOlt.comune + ": " + item.vdsl.centraleSedeOlt.indirizzo,
                                        "CLLI Area Centrale": item.vdsl.centraleSedeOlt.clli_centrale,
                                        "Nome Area Centrale" : item.vdsl.centraleSedeOlt.nome_gat,
                                        "Apparato stradale" : item.vdsl.centraleSedeOlt.apparato_stradale,
                                    }
                                })

                        if ((item.fibraIlc?.stato ?? "NO") !== "NO" && item.fibraIlc?.centraleAttestazioneP2P?.latitudine && item.fibraIlc?.centraleAttestazioneP2P?.longitudine)
                            if (parseFloat(item.fibraIlc.centraleAttestazioneP2P.latitudine) !== 0 && parseFloat(item.fibraIlc.centraleAttestazioneP2P.longitudine) !== 0)
                                this.updateMap(vm.coordinateMap,item.fibraIlc.edr, {
                                    "tipo": "Centrale",
                                    "nome": "Centrale " + item.fibraIlc.edr,
                                    "lat": parseFloat(item.fibraIlc.centraleAttestazioneP2P.latitudine),
                                    "lng": parseFloat(item.fibraIlc.centraleAttestazioneP2P.longitudine),
                                    "info" : {
                                        "ACL Area Centrale" : item.fibraIlc.centraleAttestazioneP2P.acl_area_centrale,
                                    }
                                })

                        if ((item.shdslAtm_4M?.stato ?? "NO") !== "NO" && item.shdslAtm_4M?.centraleAttestazione?.latitudine && item.shdslAtm_4M?.centraleAttestazione?.longitudine)
                            if (parseFloat(item.shdslAtm_4M.centraleAttestazione.latitudine) !== 0 && parseFloat(item.shdslAtm_4M.centraleAttestazione.longitudine) !== 0)
                                this.updateMap(vm.coordinateMap,item.shdslAtm_4M.centraleAttestazione.clli_centrale,{
                                    "tipo": item.shdslAtm_4M.tipoEdr,
                                    "nome": item.shdslAtm_4M.tipoEdr + " " + item.shdslAtm_4M.centraleAttestazione.clli_centrale,
                                    "lat": parseFloat(item.shdslAtm_4M.centraleAttestazione.latitudine),
                                    "lng": parseFloat(item.shdslAtm_4M.centraleAttestazione.longitudine),
                                    "info" : {
                                        "ACL Area Centrale" : item.shdslAtm_4M.centraleAttestazione.acl_area_centrale,
                                    }
                                })

                        if ((item.shdslAtm_8M?.stato ?? "NO") !== "NO" && item.shdslAtm_8M?.centraleAttestazione?.latitudine && item.shdslAtm_8M?.centraleAttestazione?.longitudine)
                            if (parseFloat(item.shdslAtm_8M.centraleAttestazione.latitudine) !== 0 && parseFloat(item.shdslAtm_8M.centraleAttestazione.longitudine) !== 0)
                                this.updateMap(vm.coordinateMap,item.shdslAtm_8M.centraleAttestazione.clli_centrale,{
                                    "tipo": item.shdslAtm_8M.tipoEdr,
                                    "nome": item.shdslAtm_8M.tipoEdr + " " + item.shdslAtm_8M.centraleAttestazione.clli_centrale,
                                    "lat": parseFloat(item.shdslAtm_8M.centraleAttestazione.latitudine),
                                    "lng": parseFloat(item.shdslAtm_8M.centraleAttestazione.longitudine),
                                    "info" : {
                                        "ACL Area Centrale" : item.shdslAtm_8M.centraleAttestazione.acl_area_centrale,
                                    }
                                })

                        if ((item.shdslEth_10M?.stato ?? "NO") !== "NO" && item.shdslEth_10M?.centraleAttestazione?.latitudine && item.shdslEth_10M?.centraleAttestazione?.longitudine)
                            if (parseFloat(item.shdslEth_10M.centraleAttestazione.latitudine) !== 0 && parseFloat(item.shdslEth_10M.centraleAttestazione.longitudine) !== 0)
                                this.updateMap(vm.coordinateMap,item.shdslEth_10M.centraleAttestazione.clli_centrale,{
                                    "tipo": item.shdslEth_10M.tipoEdr,
                                    "nome": item.shdslEth_10M.tipoEdr + " " + item.shdslEth_10M.centraleAttestazione.clli_centrale,
                                    "lat": parseFloat(item.shdslEth_10M.centraleAttestazione.latitudine),
                                    "lng": parseFloat(item.shdslEth_10M.centraleAttestazione.longitudine),
                                    "info" : {
                                        "ACL Area Centrale" : item.shdslEth_10M.centraleAttestazione.acl_area_centrale,
                                    }
                                })

                        if ((item.ull?.stato ?? "NO") !== "NO")
                            for (const centrale of item.ull.centraliAttestate) {
                                if (parseFloat(centrale.latitudine) !== 0 && parseFloat(centrale.longitudine) !== 0)
                                    this.updateMap(vm.coordinateMap,centrale.clli_centrale,{
                                        "tipo": item.ull.tipoEdr,
                                        "nome": item.ull.tipoEdr + " " + centrale.clli_centrale,
                                        "lat": parseFloat(centrale.latitudine),
                                        "lng": parseFloat(centrale.longitudine),
                                        "info" : {
                                            "ACL Area Centrale" : centrale.acl_area_centrale,
                                        }
                                    })
                            }

                        if ((item.wlr?.stato ?? "NO") !== "NO")
                            for (const centrale of item.wlr.centraliAttestate) {
                                if (parseFloat(centrale.latitudine) !== 0 && parseFloat(centrale.longitudine) !== 0)
                                    this.updateMap(vm.coordinateMap,centrale.clli_centrale,{
                                        "tipo": item.wlr.tipoEdr,
                                        "nome": item.wlr.tipoEdr + " " + centrale.clli_centrale,
                                        "lat": parseFloat(centrale.latitudine),
                                        "lng": parseFloat(centrale.longitudine),
                                        "info" : {
                                            "ACL Area Centrale" : centrale.acl_area_centrale,
                                        }
                                    })
                            }

                        if ((item.adslAtm?.stato ?? "NO") !== "NO") {

                            for (const centrale of item.adslAtm.centraliAttestate) {
                                if (parseFloat(centrale.latitudine.replace(",", ".")) !== 0 && parseFloat(centrale.longitudine.replace(",", ".")) !== 0)
                                    this.updateMap(vm.coordinateMap,centrale.clli,{
                                        "tipo": item.adslAtm.tipoEdr,
                                        "nome": item.adslAtm.tipoEdr + " " + centrale.clli,
                                        "lat": parseFloat(centrale.latitudine.replace(",", ".")),
                                        "lng": parseFloat(centrale.longitudine.replace(",", ".")),
                                        "info": {
                                            "CLLI Area Centrale": centrale.clli,
                                            "Nome Area Centrale" : centrale.nomeAreaCentrale,
                                            "ACL Centrale" : centrale.aclCentrale,
                                            "ACL Area Centrale" : centrale.aclAreaCentrale,
                                            "Indirizzo" : centrale.comuneCentrale + ": " + centrale.indirizzoCentrale,
                                            "ADSL ATM CLLI SGU" : centrale.clliSgu,
                                            "ADSL ATM Nome SGU" : centrale.nomeSgu
                                        }
                                    })
                            }
                        }

                        if ((item.adslEth?.stato ?? "NO") !== "NO") {

                            let centrali = item.adslEth.centraliAttestate.map((it, i) => Object.assign({}, it, item.adslEth.datiWholesale[i]));

                            for (const centrale of centrali) {
                                if (parseFloat(centrale.latitudine.replace(",", ".")) !== 0 && parseFloat(centrale.longitudine.replace(",", ".")) !== 0)
                                    this.updateMap(vm.coordinateMap,centrale.clli,{
                                        "tipo": item.adslEth.tipoEdr,
                                        "nome": item.adslEth.tipoEdr + " " + centrale.clli,
                                        "lat": parseFloat(centrale.latitudine.replace(",", ".")),
                                        "lng": parseFloat(centrale.longitudine.replace(",", ".")),
                                        "info": {
                                            "CLLI Area Centrale": centrale.clli,
                                            "Nome Area Centrale" : centrale.nomeAreaCentrale,
                                            "ACL Centrale" : centrale.aclCentrale,
                                            "ACL Area Centrale" : centrale.aclAreaCentrale,
                                            "Indirizzo" : centrale.comuneCentrale + ": " + centrale.indirizzoCentrale,
                                            "ADSL ETH CLLI SGU" : centrale.clliSgu,
                                            "ADSL ETH Nome SGU" : centrale.nomeSgu,
                                            "ADSL ETH CLLI Feeder" : centrale.clliSedeFeeder,
                                            "ADSL ETH Macroarea" : centrale.macroArea,
                                            "ADSL ETH Tipo DSLAM" : centrale.tipoDslam,
                                            "ADSL ETH ADSL" :  centrale.massimaVelocitaAdsl,
                                            "ADSL ETH Supporto VLAN" : centrale.supportoVlanSpeciali,
                                        }
                                    })
                            }
                        }

                    }

                    for (const item of json.servizi.openFiberEntries) {
                        if (parseFloat(item.indirizzoCensito.coordinateBuilding.split('_')[0].replace("N", "")) !== 0 && parseFloat(item.indirizzoCensito.coordinateBuilding.split('_')[1].replace("E", "")) !== 0)
                            vm.coordinate.push({
                                "tipo": "Casa OpenFiber",
                                "nome": item.statoScala + " OpenFiber - civico " + item.indirizzoCensito.civico + (item.indirizzoCensito.nomeScala !== "" ? " (Scala: " + item.indirizzoCensito.nomeScala + ")" : ""),
                                "lat": parseFloat(item.indirizzoCensito.coordinateBuilding.split('_')[0].replace("N", "")),
                                "lng": parseFloat(item.indirizzoCensito.coordinateBuilding.split('_')[1].replace("E", "")),
                                "info" : {
                                    "Indirizzo" : item.indirizzoCensito.via + "->" + item.indirizzoCensito.civico,
                                    "Scala" : item.indirizzoCensito.nomeScala,
                                    "Pop" : item.pop,
                                    "UI": item.totaleUi,
                                    "Bul" : item.databaseCDBul,
                                    "Stato Building": item.statoBuilding,
                                    "Stato Scala": item.statoScala,

                                }
                            })
                        if (item.popAB !== null) {
                            this.updateMap(vm.coordinateMap,item.popAB.id_pop,{
                                "tipo": "POP OF",
                                "nome": "POP OF "+ item.popAB.id_pop,
                                "lat": item.popAB.latitudine,
                                "lng": item.popAB.longitudine,
                                "info": {
                                    "Indirizzo" : item.popAB.comune + ": " + item.popAB.indirizzo,
                                    "Stato" : item.popAB.stato,
                                    "Datacenter": item.popAB.datacenter,
                                }
                            })
                        }
                        if (item.pcnCD !== null) {
                            this.updateMap(vm.coordinateMap,item.pcnCD.id_pop,{
                                "tipo": "PCN BUL",
                                "nome": "PCN BUL "+ item.pcnCD.id_pcn,
                                "lat": item.pcnCD.latitudine,
                                "lng": item.pcnCD.longitudine,
                                "info": {
                                    "Indirizzo" : item.pcnCD.comune + ": " + item.pcnCD.indirizzo,
                                    "Stato" : item.pcnCD.stato,
                                    "Datacenter": item.pcnCD.datacenter,
                                    "Data apertura": item.pcnCD.data_apertura,
                                }
                            })
                            if(item.pcnCD.pop_ab !== null) {
                                this.updateMap(vm.coordinateMap,item.pcnCD.pop_ab.id_pop,{
                                    "tipo": "POP OF",
                                    "nome": "POP OF "+ item.pcnCD.pop_ab.id_pop,
                                    "lat": item.pcnCD.pop_ab.latitudine,
                                    "lng": item.pcnCD.pop_ab.longitudine,
                                    "info": {
                                        "Indirizzo" : item.pcnCD.pop_ab.comune + ": " + item.pcnCD.pop_ab.indirizzo,
                                        "Stato" : item.pcnCD.pop_ab.stato,
                                        "Datacenter": item.pcnCD.pop_ab.datacenter,
                                    }
                                })
                            }
                        }

                    }

                    for (const bts of json.servizi?.eolo) {
                        if (parseFloat(bts.lat) !== 0 && parseFloat(bts.lng) !== 0)
                            vm.coordinate.push({
                                    "tipo": "BTS",
                                    "nome": "BTS " + bts.nome,
                                    "lat": parseFloat(bts.lat),
                                    "lng": parseFloat(bts.lng),
                                    "info" : {
                                        "Tecnologia": bts.tecnologia,
                                        "Distanza": bts.distanza,
                                        "Segnale": "da " + bts.segnale_max + "dBm a " + bts.segnale_min + "dBm",
                                    }
                                })
                    }

                    vm.coordinate.push(...vm.coordinateMap.values())

                    vm.coordinateEnabled = (vm.coordinate.length > 0)

                    vm.averageGeolocation()

                }).catch((error) => {
                  console.error('Error:', error);
                  vm.loading = false;
                  vm.error = true;
                });
                vm.loading = false;
            }).catch((error) => {
              console.error('Error:', error);
              vm.loading = false;
              vm.error = true;
            });
        }),
        updateMap(map, key, value) {
            if(map.has(key)) {
                console.log("update map")
                map.set(key, _.merge(map.get(key), value))
            } else {
                console.log("set map")
                map.set(key, value)
            }
        },
        convertDegreeToDecimal(input) {
            var parts = input.split('-');
            return this.convertDMSToDD(parts[0], parts[1], parts[2]);


        },
        convertDMSToDD(degrees, minutes, seconds) {
            return Number(degrees) + Number(minutes) / 60 + Number(seconds) / (60 * 60);

        },
        onLogin(ev) {
            ev.preventDefault();
            this.tryLogin(this);
        },
        tryLogin: _.debounce(function (vm) {

            let headers = new Headers()
            headers.append('Authorization', 'Basic ' + btoa(vm.username + ":" + vm.password));
            fetch(
                `/api/`,
                {
                    method: 'GET',
                    headers: headers,
                }
            ).then(res => {
                if (res.status === 404) {
                    vm.login = true;

                } else if (res.status === 403) {
                    vm.loginerror = true;

                } else {
                    vm.backenderror = true;

                }
            }).catch((error) => {
                console.error('Error:', error);
                vm.backenderror = true;
            })
        }, 150),
        encodeFormData(data) {
            return Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]).replace("/", "%2F"))
                .join('&');
        },

        encodeFormDataFlags(data, flags) {
            return Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]).replace("/", "%2F"))
                .join('&') + (flags !== [] ? '&' + this.generateFlagsData(flags) : '');
        },
        generateFlagsData(data) {
            return Object.keys(data).map(key => 'flags=' + encodeURIComponent(data[key]).replace("/", "%2F"))
                .join('&');
        },

        averageGeolocation() {
            if (this.coordinate.length === 1) {
                this.center = [this.coordinate[0].lat, this.coordinate[0].lng];
            }

            let x = 0.0;
            let y = 0.0;
            let z = 0.0;

            for (let coord of this.coordinate) {
                let latitude = coord.lat * Math.PI / 180;
                let longitude = coord.lng * Math.PI / 180;

                x += Math.cos(latitude) * Math.cos(longitude);
                y += Math.cos(latitude) * Math.sin(longitude);
                z += Math.sin(latitude);
            }

            let total = this.coordinate.length;

            x = x / total;
            y = y / total;
            z = z / total;

            let centralLongitude = Math.atan2(y, x);
            let centralSquareRoot = Math.sqrt(x * x + y * y);
            let centralLatitude = Math.atan2(z, centralSquareRoot);

            this.center = [centralLatitude * 180 / Math.PI, centralLongitude * 180 / Math.PI];

        }

    },
    mounted() {
        this.$refs.map.addEventListener('shown.bs.modal', event => {
            this.$refs.lmap.setupLeafletMap(event);
        });
        this.$refs.map.addEventListener('hide.bs.modal', event => {
            this.$refs.lmap.closeLeafletMap(event);
        });
    }
}
</script>

<style>
#app {

}
</style>
