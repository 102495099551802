<template>
    <div id="mapContainer"></div>

</template>

<script>
import "leaflet/dist/leaflet.css";
import "./tf-leaflet-ruler.css"
import "./tf-leaflet-ruler";
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import L from "leaflet";
import 'leaflet.markercluster/dist/leaflet.markercluster';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
    props: {
        coordinate: Array,
        center: Array,
        cluster: Boolean
    },
    data() {
        return {
            map: null
        }
    },
    name: "LMap",
    methods: {
        jsonToTable(json) {
            var text = "<table class='table table-bordered table-sm'>";
            for(const key in json)
                text += "<tr><td>"+key+"</td><td><span class='badge rounded-pill bg-info text-light'>"+json[key]+"</span></td>";

            text += "</table>"
            return text;
        },
        setupLeafletMap() {


            var armadioIcon = L.icon({
                iconUrl: 'icon/armadio.png',
                iconSize: [27, 38],
                popupAnchor: [0, -19]
            });

            var apparatoIcon = L.icon({
                iconUrl: 'icon/apparato.png',
                iconSize: [27, 38],
                popupAnchor: [0, -19]
            });

            var casaNetmapIcon = L.icon({
                iconUrl: 'icon/casaNetmap.png',
                iconSize: [38, 38],
                popupAnchor: [0, -19]
            });

            var casaOpenFiberIcon = L.icon({
                iconUrl: 'icon/casaOpenFiber.png',
                iconSize: [38, 38],
                popupAnchor: [0, -19]
            });

            var apparatoDslamIcon = L.icon({
                iconUrl: 'icon/apparato-dslam.png',
                iconSize: [27, 38],
                popupAnchor: [0, -19]
            });

            var apparatoMsanIcoon = L.icon({
                iconUrl: 'icon/msan.png',
                iconSize: [27, 38],
                popupAnchor: [0, -19]
            });

            var centraleIcon = L.icon({
                iconUrl: 'icon/centrale.png',
                iconSize: [38, 38],
                popupAnchor: [0, -19]
            });

            var btsIcon = L.icon({
                iconUrl: 'icon/bts.png',
                iconSize: [38, 38],
                popupAnchor: [0, -19]
            });

            var distributoreIcon = L.icon({
                iconUrl: 'icon/distributore.png',
                iconSize: [27, 38],
                popupAnchor: [0, -19]
            });

            var bulIcon = L.icon({
                iconUrl: 'icon/bul.png',
                iconSize: [38, 38],
                popupAnchor: [0, -19]
            });

            var popIcon = L.icon({
                iconUrl: 'icon/pop.png',
                iconSize: [38, 38],
                popupAnchor: [0, -19]
            });
            
            var cnoIcon = L.icon({

                iconUrl: 'icon/cno.png',
                iconSize: [27, 38],
                popupAnchor: [0, -19]
            });
            
            var croIcon = L.icon({
                iconUrl: 'icon/cro.png',
                iconSize: [27, 38],
                popupAnchor: [0, -19]
            });

            var pteIcon = L.icon({
                iconUrl: 'icon/pteFibercop.png',
                iconSize: [27, 38],
                popupAnchor: [0, -19]
            });


            this.map = L.map("mapContainer").setView(this.center, 13);
            L.tileLayer(
                "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                {
                    attribution:
                        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                }
            ).addTo(this.map);

            L.control.ruler({}).addTo(this.map);

            let markers = this.cluster ? L.markerClusterGroup({chunkedLoading: true,  disableClusteringAtZoom: 16 }) : null;

            for (const point of this.coordinate) {
                var theIcon = null;
                switch (point.tipo) {
                    case "Armadio":
                        theIcon = armadioIcon;
                        break;
                    case "Apparato":
                        theIcon = apparatoIcon;
                        break;
                    case "Apparato con DSLAM":
                        theIcon = apparatoDslamIcon;
                        break;
                    case "Apparato MSAN":
                        theIcon = apparatoMsanIcoon;
                        break;
                    case "Centrale":
                        theIcon = centraleIcon;
                        break;
                    case "BTS":
                        theIcon = btsIcon;
                        break;
                    case "Casa netmap":
                        theIcon = casaNetmapIcon;
                        break;
                    case "Casa OpenFiber":
                        theIcon = casaOpenFiberIcon;
                        break;
                    case "Distributore":
                        theIcon = distributoreIcon;
                        break;
                    case "POP OF":
                        theIcon = popIcon;
                        break;
                    case "PCN BUL":
                        theIcon = bulIcon;
                        break;
                    case "CNO":
                        theIcon = cnoIcon;
                        break;
                    case "CRO":
                        theIcon = croIcon;
                        break;
                    case "PTE FiberCop":
                        theIcon = pteIcon;
                        break;
                }
                if(theIcon != null) {
                    let marker = L.marker([point.lat, point.lng], {icon: theIcon})
                        .bindPopup(point.nome + "<br>" + this.jsonToTable(point.info));
                    if(this.cluster) {
                        markers.addLayer(marker);
                    }
                    else {
                        marker.addTo(this.map);
                    }
                } else {
                    let marker = L.marker([point.lat, point.lng]).bindPopup(point.nome + "<br>" + this.jsonToTable(point.info));
                    if(this.cluster) {
                        markers.addLayer(marker);
                    }
                    else {
                        marker.addTo(this.map);
                    }
                }
            }
            if(this.cluster) {
                this.map.addLayer(markers);
            }
        },
        closeLeafletMap() {
            this.map.off();
            this.map.remove();
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
#mapContainer {
    height: 100%;
}
</style>
